import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import Breadcrumbs from '../components/Breadcrumbs';
import CustomMDXProvider from '../components/CustomMDXProvider';
import List from '../components/List';
import NOPSidebarBanner from '../components/NOPSidebarBanner';
import Seo from '../components/Seo';
import ShareButtons from '../components/ShareButtons';

import escapeForHTML from '../utils/escapeForHTML';

const TalePage = ({ data, location, pageContext }) => {
  const { tale, site } = data;

  return (
    <>
      <Seo
        title={`${escapeForHTML(tale.title)} - українська народна казка`}
        description={`Українська народна казка “${escapeForHTML(tale.title)}”. Текст та аудіозапис казки “${escapeForHTML(tale.title)}”.`}
      >
        <script type='application/ld+json'>
          {`{
              "@context": "https://schema.org",
              "@type": "CreativeWork",
              "name": "${escapeForHTML(tale.title)}",
              "description": "Українська народна казка: ${escapeForHTML(tale.title)}",
              "abstract": "Українська народна казка: ${escapeForHTML(tale.title)}",
              "author": "${tale.author}",
              "url": "${site.siteMetadata.siteUrl}${location.pathname}",
              "accessMode": "textual"
          }`}
        </script>
      </Seo>
      <div className="container-md py-10 md:py-20">
        <Breadcrumbs
          crumbs={pageContext.breadcrumb.crumbs}
          crumbLabel={tale.title}
        />
        <h1 className="typo-h1 mt-4">
          {tale.title}
        </h1>
        <div className="row mt-4 md:mt-10">
          <div className="col-full md:col-8">
            <CustomMDXProvider>
              <MDXRenderer>
                {tale.content.body}
              </MDXRenderer>
            </CustomMDXProvider>
          </div>
          <div className="col-full md:col-4 mt-8 md:mt-0">
            <NOPSidebarBanner medium="tale-sidebar" />
          </div>
        </div>
        {tale.books && (
          <div className="mt-5 md:mt-14">
            <h2 className="typo-h2">
              Джерела та збірки:
            </h2>
            <p className="typo-body mt-4">
              {`Текст української народної казки "${tale.title}" входить до таких джерел та збірок:`}
            </p>
            <List className="mt-4">
              {tale.books.map((book) => (
                <List.Item className="typo-body">
                  {book}
                </List.Item>
              ))}
            </List>
          </div>
        )}
        <div className="mt-5 md:mt-14">
          <h3 className="typo-h3">Поділитися</h3>
        </div>
        <ShareButtons
          className="mt-4"
          title={tale.title}
          url={`${site.siteMetadata.siteUrl}${location.pathname}`}
        />
      </div>
    </>
  )
};

export default TalePage;

export const pageQuery = graphql`
  query($slug: String!) {
    tale: tale(slug: { eq: $slug }) {
      title
      books
      content {
        body
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
